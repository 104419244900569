<template>
  <div id="app">
    <app-header v-if="header_show"></app-header>
    <router-view :key="$route.fullPath" @header="header" @footer="footer" />
    <keep-alive>
      <app-footer v-if="footer_show"></app-footer>
    </keep-alive>
  </div>
</template>
<script>
import head from "./components/head";
import Footer from "./components/footer";

export default {
  name: "App",
  components: {
    "app-header": head,
    "app-footer": Footer,
  },
  data() {
    return {
      header_show: true,
      footer_show: true,
    };
  },
  mounted() {
    if (this._isMobile()) {
      //alert("手机端");
      // window.location.href = "https://lg.csctesting.cn/h5/";
      window.location.href = "https://csctalent.globilityacademy.com/h5/";
    }
  },

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //是否显示底部
    footer(bool) {
      this.footer_show = bool;
    },
    // this.$emit(‘header’,false); 如果不需要显示
    //是否显示头部
    header(bool) {
      this.header_show = bool;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Microsoft YaHe, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_box {
  overflow: hidden;

  img {
    transition: all 0.4s linear;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.line-1 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-3 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-4 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

* {
  margin: 0;
  padding: 0;
}

.w {
  width: 1300px;
  margin: 0 auto;
}

div {
  box-sizing: border-box;
}
</style>
