<template>
  <div>
    <div style="height: 100px"></div>
    <div class="head flex">
      <div class="w flex head_box">
        <img :src="sys_config_data.web_logo" class="logo" v-if="sys_config_data.web_logo" alt=""
          @click="go_page('/')" />
        <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="/" @click="go_page('/')">
            <span class="line-s">首页</span>
            <div class="line"></div>
          </el-menu-item>
          <el-menu-item index="/all_course" @click="go_page('/all_course')">
            <el-dropdown @command="handleCommand2" placement="bottom">
              <div class="el-dropdown-link">
                <span class="line-s">课程系列</span>
                <div class="line"></div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item.id" v-for="(item, i) in select_options_list.series" :key="i">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
          <el-menu-item index="/recommend_course" @click="go_page('/recommend_course')">
            <el-dropdown @command="handleCommand3" placement="bottom">
              <div class="el-dropdown-link">
                <span class="line-s">全部课程</span>
                <div class="line"></div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item.id" v-for="(item, i) in books_list" :key="i">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>

          <el-menu-item index="/news" @click="go_page('/news')">
            <span class="line-s">新闻活动</span>
            <div class="line"></div>
          </el-menu-item>
          <el-menu-item index="/certificate" @click="go_page('/certificate')">
            <span class="line-s">证书查询</span>
            <div class="line"></div>
          </el-menu-item>
          <el-menu-item index="/resource_download" @click="go_page('/resource_download')">
            <span class="line-s">资源下载</span>
            <div class="line"></div>
          </el-menu-item>
        </el-menu>
        <div class="flex">
          <div class="serach_box flex">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <el-select v-model="value" filterable remote reserve-keyword placeholder="请输入关键字进行搜索"
              :remote-method="remoteMethod" :loading="loading" class="search" @change="todetail">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <!-- <el-input placeholder="请输入关键字进行搜索" v-model="keyword" class="search">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input> -->
            <div class="btn">搜索</div>
          </div>
          <div class="info flex btn" v-if="token">
            <el-dropdown @command="handleCommand" v-if="info">
              <span class="el-dropdown-link flex">
                <img :src="info.avatar_url" alt="" v-if="info.avatar_url" />
                <img src="@/assets/tx.png" alt="" v-else />
                <span>{{ info.nick_name }}</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">我的订单</el-dropdown-item>
                <el-dropdown-item command="1">已选课程</el-dropdown-item>
                <el-dropdown-item command="2">我的证书</el-dropdown-item>
                <el-dropdown-item command="3">用户信息</el-dropdown-item>
                <!-- <el-dropdown-item command="4">申请发票</el-dropdown-item> -->
                <!-- <el-dropdown-item command="4">意见反馈</el-dropdown-item> -->
                <el-dropdown-item command="6">我的反馈</el-dropdown-item>
                <el-dropdown-item command="5">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="info flex btn" style="color: #103994" v-else @click="go_login">
            登录
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  my_user,
  index_search,
  index_recommend_categories,
  sys_config,
} from "@/api/login";
import { select_options } from "@/api/series";
export default {
  components: {},
  props: {},
  data() {
    return {
      activeIndex: "1",
      keyword: "",
      // info: {},
      img: "",
      token: "",
      select_options_list: {},
      books_list: [],
      options: [],
      value: [],
      list: [],
      loading: false,
      states: [],
      sys_config_data: {},
    };
  },
  computed: {
    info() {
      return this.$store.state.user;
    },
  },
  watch: {},
  mounted() {
    this.list = this.states.map((item) => {
      return {
        value: `value:${item}`,
        label: `label:${item}`,
      };
    });
  },
  created() {
    if (localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
      this.getinfo();
    }
    this.get_select_options();
    this.get_options();
    this.get_sys_config();
  },

  // 组件方法
  methods: {
    get_sys_config() {
      sys_config().then((res) => {
        this.sys_config_data = res.data;
      });
    },
    handleCommand2(e) {
      this.activeIndex = "2";
      this.go_pages(e);
    },
    todetail(id) {
      let obj = {};
      obj = this.options.find((item) => {
        return item.id == id;
      });

      if (obj.type == 2) {
        this.$router.push({
          path: "/course_details",
          query: {
            id: id,
          },
        });
      } else if (obj.type == 1) {
        this.$router.push({
          path: "/course_series",
          query: {
            series: id,
          },
        });
      } else if (obj.type == 3) {
        this.$router.push({
          path: "/resource_download",
          query: {
            id: id,
          },
        });
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        index_search({
          key_words: query,
        }).then((res) => {
          this.loading = false;
          this.options = res.data;
        });
        // setTimeout(() => {
        // 	this.loading = false;
        // }, 200);
      } else {
        this.options = [];
      }
    },
    get_select_options() {
      select_options().then((res) => {
        this.select_options_list = res.data;
      });
    },
    get_options() {
      index_recommend_categories().then((res) => {
        this.books_list = res.data;
      });
    },
    getinfo() {
      my_user().then((res) => {
        this.$store.commit("get_info", res.data);
        localStorage.setItem("info", JSON.stringify(res.data));
        // this.info = res.data;

        // localStorage.setItem("info", JSON.stringify(res.data));
        // this.img = JSON.parse(this.info.personal_photos)
        // localStorage.setItem("avatar", this.info.avatar_url);
      });
    },
    go_login() {
      this.$router.push({
        path: "/login",
      });
    },
    handleCommand3(e) {
      this.activeIndex = "3";
      this.$router.push({
        path: "/recommend_course",
        query: {
          id: e,
        },
      });
    },
    go_pages(id) {
      // go_page('/?series=' + item.id)
      this.$router.push({
        path: "/course_series",
        query: {
          series: id,
        },
      });
      // let routeUrl = this.$router.resolve({
      //   path: '/course_series',
      //   query: {
      //     series: id,
      //   }
      // })
      // window.open(routeUrl.href, '_blank')
    },
    go_page(url) {
      this.$router.push(url);
    },
    handleSelect(key, keyPath) { },
    handleCommand(e) {
      this.activeIndex = "0";
      let active = document.getElementsByClassName("is-active")[0];
      // active.classList.remove('is-active')
      // document.querySelector('.el-menu-item').classList.remove('is-active')
      if (e == 5) {
        window.localStorage.clear();
        this.$router.push({
          path: "/login",
        });
      } else if (e == 4) {
        this.$router.push("/feedback");
      } else if (e == 6) {
        this.$router.push("/myFeedback");
      } else {
        this.$router.push({
          path: "/info",
          query: {
            current: e,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.line-s {
  padding-top: 5px;
}

::v-deep .el-dropdown-menu__item {
  padding: 5px 50px;
}

::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #103994 !important;
  color: #fff !important;
}

::v-deep .el-dropdown {
  font-size: 16px !important;
  font-family: Microsoft YaHei !important;
}

::v-deep .el-input__inner {
  cursor: text !important;
}

::v-deep .el-select__caret {
  cursor: text !important;
}

.head {
  width: 100%;
  height: 100px;
  background: #ffffff;
  // box-shadow: 0px 5px 10px 3px #dbdbdb;
  border-radius: 1px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .head_box {
    justify-content: space-between;
  }

  .info {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-left: 45px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      margin-right: 20px;
    }
  }
}

.serach_box {
  background: #ffffff;
  border: 1px solid #103994;
  border-radius: 16px;
  overflow: hidden;
  width: 272px;
  // width: 302px;
  justify-content: space-between;
  margin-right: 20px;
  padding-left: 10px;
  box-sizing: border-box;

  .search {
    width: 242px;
    height: 33px;
    line-height: 33px;
  }

  .btn {
    width: 70px;
    height: 33px;
    background: #103994;
    border-radius: 0px 16px 16px 0px;
    text-align: center;
    color: #fff;
    line-height: 33px;
    font-size: 14px;
  }
}

.logo {
  width: 65px;
  height: 65px;
  //   border-radius: 64px;
  object-fit: contain;
  // background-color: pink;
  // margin-right: 30px;
  cursor: pointer;
}

::v-deep .el-menu-item {
  padding: 0 30px;
}

::v-deep .el-menu.el-menu--horizontal {
  border: none;
}

::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #103994 !important;
}

::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid transparent;
  font-size: 16px !important;
  font-family: Microsoft YaHei !important;
  font-weight: bold;
  color: #103994 !important;
  position: relative;

  .line {
    width: 40px;
    height: 3px;
    background-color: #103994;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid transparent;
  font-size: 16px !important;
  font-family: Microsoft YaHei !important;
  font-weight: bold;
  color: #103994;
  position: relative;

  .line {
    width: 40px;
    height: 3px;
    background-color: #103994;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

::v-deep .el-menu--horizontal>.el-menu-item {
  font-size: 16px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 400;
  color: #333333;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
  font-size: 16px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 400;
  color: #333333;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  display: none;
}

::v-deep .el-input__inner {
  height: 33px;
  line-height: 33px;
  border: none;
}

::v-deep .el-input__icon {
  line-height: 33px;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 70px;
  line-height: 70px;
}

::v-deep .el-menu--horizontal>.el-menu-item {
  height: 70px;
  line-height: 70px;
}

::v-deep .el-dropdown {
  color: inherit !important;
}
</style>